<template>
  <div>
    <div class="text-right mb-2">
      <b-button
        variant="primary"
        to="/tour-register/step-1/new"
        @click="$store.commit('tour/updateProperty', {})"
      >
        Add Tour
      </b-button>
    </div>
    <b-card no-body>
      <!--table top-->
      <div class="m-2">
        <b-row class="justify-content-end">
          <!--search-->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center">
              <b-form-input
                v-model="searchQuery"
                class="mr-1"
                placeholder="Search..."
                @input="debounceSearch(meta.offset= 0, getTourList)"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- <tour-form /> -->
      <b-table
        id="tourTokenizer"
        :items="tourList"
        :fields="tableColumns"
        :busy="isTableLoading"
        primary-key="id"
        empty-text="No matching records found"
        responsive
        show-empty
      >
        <template #table-busy>
          <div class="text-center text-primary m-2">
            <b-spinner class="align-middle" />
            <strong> Loading...</strong>
          </div>
        </template>

        <template #cell(is_active)="data">
          <b-badge
            pill
            :variant="data.item.is_active ? 'light-success' : 'light-danger'"
          >{{ data.item.is_active ? 'Active' : 'Inactive' }}</b-badge>
        </template>

        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              class="action-menu"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <!-- Status -->
              <b-dropdown-item
                v-b-modal.tour-status
                @click="getTourData(data.item)"
              >
                <feather-icon :icon="data.item.is_active ? 'SlashIcon' : 'CircleIcon'" />
                <span
                  class="align-middle ml-50"
                >
                  {{ data.item.is_active ? 'Deactivate' : 'Activate' }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
            <feather-icon
              :id="`${data.item.id}-preview-icon`"
              icon="Edit2Icon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="updateSelectedTour(data.item, '/tour-register/step-1/')"
            />
            <feather-icon
              icon="CalendarIcon"
              size="16"
              class="cursor-pointer"
              @click="$router.push({ name: 'availability', params: { id: data.item.id } })"
            />
          </div>
        </template>
      </b-table>
      <TablePagination
        :limit="meta.limit"
        :list-data="tourList"
        :total="meta.total"
        @paginate="paginate"
      />
    </b-card>
    <b-modal
      id="tour-status"
      ref="tour-status"
      ok-only
      ok-variant="primary"
      modal-class="modal-primary"
      centered
      title="Confirmation"
    >
      <b-card-text>
        Are you sure you want to {{ tourData.is_active ? 'deactivate' : 'activate' }} this tour ?
      </b-card-text>
      <template #modal-footer>
        <b-button
          :disabled="isTableLoading"
          variant="primary"
          @click="changeTourStatus()"
        >
          <b-spinner
            v-if="isTableLoading"
            small
          />
          Confirm
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '@/libs/api'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import TablePagination from '@/components/common/TablePagination.vue'

export default {
  name: 'TourList',
  components: {
    TablePagination,
  },
  mixins: [helper, calendar],
  data() {
    return {
      tourList: [],
      isTableLoading: false,
      meta: {
        offset: 0,
        limit: 10,
        total: 0,
      },
      // Table Handlers
      tableColumns: [
        { key: 'actions', label: '' },
        { key: 'name', thClass: 'md' },
        { key: 'id', label: 'Tour Id' },
        {
          key: 'location.name', thClass: 'md', label: 'Location',
        },
        { key: 'duration', formatter: value => this.durationToText(value) || '' },
        {
          key: 'is_active',
          label: 'status',
        },
      ],
      requestType: '',
      tourInfo: {},
      searchQuery: '',
      tourData: {},
    }
  },
  computed: {
    filterQuery() {
      const search = this.searchQuery ? `&search=${this.searchQuery}` : ''
      return `${search}`
    },
  },
  mounted() {
    this.getTourList()
  },
  methods: {
    changeTourStatus() {
      this.isTableLoading = true
      api.updateData(`tour/admin_services/tour/${this.tourData.id}/status_update/`, true,
        { is_active: !this.tourData.is_active })
        .then(response => {
          if (response.data.status) {
            this.getTourList()
            this.$refs['tour-status'].hide()
          }
        }).catch().finally(() => {
          this.isTableLoading = false
        })
    },
    getTourList() {
      this.isTableLoading = true
      api.getData(`tour/admin_services/tour_list/?offset=${this.meta.offset}${this.filterQuery}`, true)
        .then(response => {
          if (response.data.status) {
            this.tourList = response.data.result.results
            this.meta = response.data.result.meta
          }
        }).catch().finally(() => {
          this.isTableLoading = false
        })
    },
    updateSelectedTour(property, url) {
      this.$store.commit('tour/updateProperty', property)
      this.$router.push(url + property.id)
    },
    paginate(page) {
      this.meta.offset = (page - 1) * 10
      this.getTourList()
    },
    getTourData(tourData) {
      this.tourData = tourData
    },
    resetData() {
      this.requestType = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/style.scss';
</style>
